import { Button } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import React from 'react'

export interface FilterProps {
  filterTime: FilterTimeType
  setFilterTime: SetFitlerTimeType
}

export type SetFitlerTimeType = React.Dispatch<React.SetStateAction<FilterTimeType>>
export type FilterTimeType = '1d' | '2d' | '1w' | '2w' | '1m'

export const DEFAULT_FILTER_TIME: FilterTimeType = '1d'

export function Filter({ filterTime, setFilterTime }: FilterProps): JSX.Element {
  const classes = useStyles()

  return (
    <div className={classes.filterButtonsContainer}>
      <FilterButton filterTime={filterTime} title={'1d'} setFilterTime={setFilterTime} />
      <FilterButton filterTime={filterTime} title={'2d'} setFilterTime={setFilterTime} />
      <FilterButton filterTime={filterTime} title={'1w'} setFilterTime={setFilterTime} />
      <FilterButton filterTime={filterTime} title={'2w'} setFilterTime={setFilterTime} />
      <FilterButton filterTime={filterTime} title={'1m'} setFilterTime={setFilterTime} />
    </div>
  )
}

function FilterButton({ filterTime, title, setFilterTime }): JSX.Element {
  const classes = useStyles()

  return (
    <Button
      className={filterTime === title ? classes.activeButton : classes.normalButton}
      onClick={() => {
        setFilterTime(title)
      }}
    >
      {title}
    </Button>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    stabilityReportContainer: {
      marginRight: -15,
      marginLeft: -15,
    },
    updatedAtText: {
      fontStyle: 'italic',
      marginBottom: 30,
    },
    filterButtonsContainer: {
      border: '1px solid #e4e4e4',
      display: 'inline-block',
      borderRadius: 4,
      overflow: 'hidden',
    },
    normalButton: {
      backgroundColor: '#efefef',
      borderRadius: 0,
    },
    activeButton: {
      backgroundColor: 'rgb(0, 123, 255)',
      color: '#fff',
      borderRadius: 0,
      '&:hover': {
        backgroundColor: 'rgb(0, 123, 255)',
      },
    },
    card: {
      padding: 16,
      paddingBottom: 24,
      background: '#fff',
      minWidth: 185,
      display: 'inline-block',
      margin: '0 15px 30px',
      border: 'none',
      borderRadius: '1rem',
      boxShadow:
        '0 2px 0 rgb(90 97 105 / 11%), 0 4px 8px rgb(90 97 105 / 12%), 0 10px 10px rgb(90 97 105 / 6%), 0 7px 70px rgb(90 97 105 / 10%)',
      textAlign: 'center',
    },
    cardTitle: {
      color: '#000',
      fontSize: 22,
      padding: 0,
      margin: 0,
    },
    cardValue: {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: 16,
      padding: 0,
      margin: 0,
    },
  })
)
